
















































import Vue, { VueConstructor } from "vue";
import { createPopper } from "@popperjs/core";
import { UserCompanyStore } from "@/store";
import { UserPreferences } from "@/models/UserCompanyModel";

interface TourSteps {
  loadlists: TourStepData[];
  loadlist: TourStepData[];
  setup: TourStepData[];
  loadplan: TourStepData[];
  detail: TourStepData[];
  workspace: TourStepData[];
}

interface TourStepData {
  title: string;
  content: string;
  target?: string;
  placement?: string;
  scrollTo?: boolean;
  virtualTarget?: any;
}

const steps: TourSteps = {
  loadlists: [
    {
      target: "#loadlistsTable tr",
      title: "Loadlists",
      content: `This is where your loadlists will be found. All loadlists you create are automatically saved.`,
    },
    {
      target: "#projectsToolbar",
      title: "Projects",
      content: `You can also create projects, to which you can assign loadlists. This makes it easier to keep structure as the number of loadlists grow. <br><br>Users within the same organization are able to view your loadlists through the "Colleagues" project. You can set a project to be priviate which means they will only be visible to you. <br><br>You move lists between different projects by utilizing drag-and-drop with your mouse `,
    },
  ],
  loadlist: [
    {
      target: "#mainTitle",
      title: "The loadlist view",
      content: `This is where you work with a particular loadlist`,
      placement: "top",
    },
    {
      target: "#mainDataTab",
      title: "Cargoes",
      content: `The first thing you need to do is to enter cargo details`,
      placement: "top",
    },
    {
      target: "#mainEqupmentTab",
      title: "Equipment",
      content: `Next you select what equipment you want to use`,
      placement: "top",
    },
    {
      target: "#mainWorkspaceTab",
      title: "Workspace view",
      content: `Then you will see the result were you can also modify it. We call this the workspace`,
      placement: "top",
    },
    {
      target: "#mainLoadplanTab",
      title: "Loadplan",
      content: `In this final step you will get a detailed loadplan`,
      placement: "top",
    },
    {
      virtualTarget: {
        getBoundingClientRect: (function generateGetBoundingClientRect() {
          return () => ({
            width: 0,
            height: 0,
            top: window.innerHeight * 0.5,
            right: window.innerWidth * 0.5,
            bottom: window.innerHeight * 0.5,
            left: window.innerWidth * 0.5,
          });
        })(),
      },
      title: "Cargo table",
      content: `This is where you enter your cargo details. <strong>You can copy and paste whole rows and columns straight from Excel </strong> or enter manually.<br><br>Length, Width, Height and Weight are required!<br><br><strong>Hint: </strong>Do you have cargo data in the form of LxWxH? Select a row in the Length column and press Ctrl+V - it will then insert Length, Width and Height accordingly`,
    },
    {
      virtualTarget: {
        getBoundingClientRect: (function generateGetBoundingClientRect() {
          return () => ({
            width: 0,
            height: 0,
            top: window.innerHeight * 0.5,
            right: window.innerWidth * 0.5,
            bottom: window.innerHeight * 0.5,
            left: window.innerWidth * 0.5,
          });
        })(),
      },
      title: "3 different modes",
      content: `Depending on what you enter in the Qty (Quantity) column - one of three different calculation modes will happen: <br><br>If <strong>all</strong> rows has a Quantity set, then the tool load the cargoes in as many containers as is necessary.<br><br>If <strong>no</strong> rows has a quantity set - then the tool will try to fit as much as possible into one of the selected containers. <br><br> If <strong>some</strong> rows has a quantity set and some has not - then the tool will load the cargoes that has a quantity set in as many containers as needed - and then use the items that has no quantity set as "fillers"`,
    },
    {
      target: "#editOptions",
      title: "Import",
      content: `Click here for more functions - like the Cargo library where you can save your cargoes and import them here later on.<br><br>You will also find the packlist reader which you can use to read Excel packing lists`,
    },
    {
      target: "#editLengthDimSelect",
      title: "Dimensions",
      content: `Select length and weight dimensions for the cargoes. <strong>Then click save and continue in the bottom</strong>`,
      scrollTo: true,
    },
  ],
  setup: [
    {
      target: "#mainEqupmentTab",
      title: "Equipment",
      content: `This is where you select what kind of equipment you want to load your cargoes in`,
    },
    {
      target: "#equipmentLibrary",
      title: "Library",
      content: `This panel contains a library with available equipment`,
      placement: "top",
    },
    {
      target: "#equipmentLibraryFilter",
      title: "Filter",
      content: `Here you can select what kind of equipment you want to see. <br><br>The <i>Standard library</i> contains system defined containers. <br><br><i>Our library</i> consists of containers belonging to your organization`,
      placement: "top",
    },
    {
      target: "#equipmentSelectedHolds",
      title: "Equipment available to this loadplan",
      content: `In this panel you select which containers / trailers / pallets are available for <strong>this</strong> particular loadplan. You can select only one, or multiple and let the tool decide the best combination`,
      placement: "top",
    },
    {
      target: "#equipmentSelectedHolds .row",
      title: "Container settings",
      content: `If you click on a container in this panel, you can edit some properties like payload, max loading height etc`,
      placement: "top",
    },
    {
      target: "#equipmentLoadConfigurationButton",
      title: "Load presets",
      content: `If you find yourself always using the same container setup, settings and pallet types you can create a load preset. You can then import the preset - or even set it as the default one!`,
      placement: "top",
    },
    {
      target: "#equipmentLoadSettingsButton",
      title: "Load settings",
      content: `Here you can set different settings and rules for the calculation`,
      placement: "top",
    },
    {
      target: "#tab-pallets",
      title: "Pallets",
      content: `Maybe you want to pre-load some items on pallets before loading them into containers? If so, then add a pallet type here - and also check the "preload" column in the 1. Data view`,
      placement: "top",
    },
    {
      target: "#tab-sets",
      title: "Airplanes / Sets",
      content: `If we want to load entire road-trains or airplanes at once, you can find those here.`,
      placement: "top",
    },
    {
      target: "#equipmentLoadplanVersions",
      title: "Loadplan versions",
      content: `Here you can create different versions or revisions, using different containers and settings`,
      placement: "top",
    },
    {
      target: "#equipmentCalculateBtn",
      title: "Create a loadplan",
      content: `When you are done - click here to create a load plan`,
      placement: "top",
    },
  ],
  workspace: [
    {
      target: "#mainWorkspaceTab",
      title: "Workspace",
      content: `Here you can find and modify the current load plan. `,
    },
    {
      target: "#workspaceTable tbody tr ",
      title: "Containers used in this loadplan",
      content: `Each row represents one or more identical loaded containers`,
    },
    {
      target: "#setWorkspaceTable tbody tr",
      title: "Sets used in this loadplan",
      content: `Each row represents one or more identical loaded sets`,
    },
    {
      target: "#plannerTable .plannerContainer",
      title: "Containers used in this loadplan",
      content: `Each card with a represents one or more identical containers.`,
    },
    {
      target: "#sceneComponent",
      title: "The Interactive view!",
      content: `If you click here you will enter the interacative view where you can do customizations on cargo level - or just want to spin around and see your container in more detail`,
    },
    {
      target: "#plannerTable .plannerContainer .item",
      title: "A loaded cargo",
      content: `Each card represents one or more cargoes in this container. <br><br>You can drag the card between containers or up and down within a container to quickly change the order.`,
    },
    {
      target: "#viewToggleButton",
      title: "Set the view you prefer",
      content: `
      Table view - Preferred for printing and overviewing the dimensions and weights of containers.<br><br>
      Planner view - Useful for quickly rearranging your cargo between and within containers<br><br>
      Airplane/Set View - See how the containers are arranged in an airplane/road-train
      `,
    },
    {
      target: "#workspaceMoreBtn",
      title: "Modifications",
      content: `Here you can do some modifications on container level like adding new empty ones, deleting or changing containers to other types`,
    },
    {
      target: "#unloadedItemsToolbar",
      title: "Unloaded items",
      content: `If a cargo cannot be fit into selected container types, either because they are too big, too heavy or there are any other setting or rules preventing it from being loaded - they will pop up into this window. They will also pop up here if you manually delete a container with items or delete items from the interactive view<br><br>Click on the warning triangle to see which items are not loaded. Here you can also "drag" one or several items into a selected container if you want to load some items manually`,
    },
  ],
  detail: [
    {
      target: "#appTitle",
      title: "The Interactive view!",
      content: `Here you can use your mouse to interact with the container and individual cargoes`,
    },
    {
      virtualTarget: {
        getBoundingClientRect: (function generateGetBoundingClientRect() {
          return () => {
            let interactiveScene: HTMLElement = document.querySelector(
              "#interactiveScene"
            );
            return {
              width: 0,
              height: 0,
              top:
                interactiveScene.offsetTop +
                interactiveScene.offsetHeight * 0.5,
              right:
                interactiveScene.offsetLeft +
                interactiveScene.offsetWidth * 0.5,
              bottom:
                interactiveScene.offsetTop +
                interactiveScene.offsetHeight * 0.5,
              left:
                interactiveScene.offsetLeft +
                interactiveScene.offsetWidth * 0.5,
            };
          };
        })(),
      },
      title: "Move the camera",
      content: `If you place the mouse on a void space, meaning <strong>not</strong> on a cargo, click - and drag - you will be able to change the perspecive. If you scroll - you can zoom in and out`,
    },
    {
      target: "#interactiveCameraButton",
      title: "Camera shortcuts",
      content: `Click here to switch the camera view to Top, Side, Front or 3D. You can also press button <kbd>1</kbd>,<kbd>2</kbd>,<kbd>3</kbd> and <kbd>4</kbd> on your keyboard.<br><br>Note - when in Top,Side or Front view - you are <strong>not</strong> able to move the camera.`,
    },
    {
      virtualTarget: {
        getBoundingClientRect: (function generateGetBoundingClientRect() {
          return () => {
            let interactiveScene: HTMLElement = document.querySelector(
              "#interactiveScene"
            );
            return {
              width: 0,
              height: 0,
              top:
                interactiveScene.offsetTop +
                interactiveScene.offsetHeight * 0.5,
              right:
                interactiveScene.offsetLeft +
                interactiveScene.offsetWidth * 0.5,
              bottom:
                interactiveScene.offsetTop +
                interactiveScene.offsetHeight * 0.5,
              left:
                interactiveScene.offsetLeft +
                interactiveScene.offsetWidth * 0.5,
            };
          };
        })(),
      },
      title: "Select a cargo",
      content: `IF you place the mouse over a cargo you will see that it gets highlighted. If you at the same time click on it - it will become selected. You can select multiple cargoes by holding the Ctrl button at the same time.`,
    },
    {
      virtualTarget: {
        getBoundingClientRect: (function generateGetBoundingClientRect() {
          return () => {
            let interactiveScene: HTMLElement = document.querySelector(
              "#interactiveScene"
            );
            return {
              width: 0,
              height: 0,
              top:
                interactiveScene.offsetTop +
                interactiveScene.offsetHeight * 0.5,
              right:
                interactiveScene.offsetLeft +
                interactiveScene.offsetWidth * 0.5,
              bottom:
                interactiveScene.offsetTop +
                interactiveScene.offsetHeight * 0.5,
              left:
                interactiveScene.offsetLeft +
                interactiveScene.offsetWidth * 0.5,
            };
          };
        })(),
      },
      title: "Move cargo",
      content: `If you now press and drag with your mouse you can move the cargo to another position. You can also do it by using the keyboard arrow buttons. It is usually easier to do this when in the <strong>Top</strong> view<br><br>You can also press the <kbd>s</kbd> button on your keyboard to access the snap move mode. In this mode, you will move cargoes from one point to another`,
    },
    {
      virtualTarget: {
        getBoundingClientRect: (function generateGetBoundingClientRect() {
          return () => {
            let interactiveScene: HTMLElement = document.querySelector(
              "#interactiveScene"
            );
            return {
              width: 0,
              height: 0,
              top:
                interactiveScene.offsetTop +
                interactiveScene.offsetHeight * 0.5,
              right:
                interactiveScene.offsetLeft +
                interactiveScene.offsetWidth * 0.5,
              bottom:
                interactiveScene.offsetTop +
                interactiveScene.offsetHeight * 0.5,
              left:
                interactiveScene.offsetLeft +
                interactiveScene.offsetWidth * 0.5,
            };
          };
        })(),
      },
      title: "Unload cargo",
      content: `If you press the <kbd>delete</kbd> or <kbd>backspace</kbd> button on your keyboard while a cargo is selected, you will remove it from the container and it will be popped up in the unloaded items window`,
    },
  ],
  loadplan: [
    {
      target: "#mainLoadplanTab",
      title: "Loadplan view",
      content: `This is the load plan view view. Here you will find a detailed report on which cargoes are loaded in which containers`,
    },
    {
      target: "#loadplanViewSettingsMenu",
      title: "View settings",
      content: `Here you can control what information you want to be visible - like load instructions, watermarked reports, detailed information etc`,
    },
    {
      target: "#loadplanSequencesBtn",
      title: "Sequences",
      content: `If you want a detailed loading description you can select how many loading sequences you want to show for each container. You can select one image per cargo per container if you want, but be careful choosing this mode if you how many items`,
    },
    {
      target: "#loadplanPrintButton",
      title: "Print & PDF",
      content: `Click here to print the load plan - or save it as a PDF`,
    },
    {
      target: "#loadplanDownloadExcelButton",
      title: "Load plan in excel",
      content: `Click here download the load plan in Excel`,
    },
    {
      target: "#loadplanColumnsButton",
      title: "Visible columns",
      content: `Click here set which columns should be visible in the load plan`,
    },
  ],
};
let popperInstance: any = null;

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      tooltip: any;
    };
  }
>).extend({
  name: "app-tour",
  data() {
    return {
      currentStep: 0,
      show: false,
    };
  },
  computed: {
    steps(): TourStepData[] {
      return steps[this.$route.name as keyof TourSteps];
    },
    isLastStep(): boolean {
      return this.currentStep === this.steps.length - 1;
    },
    currentTitle(): string {
      if (this.steps && this.steps[this.currentStep]) {
        return this.steps[this.currentStep].title;
      }
      return "";
    },
    currentContent(): string {
      if (this.steps && this.steps[this.currentStep]) {
        return this.steps[this.currentStep].content;
      }
      return "";
    },
    preferences(): UserPreferences {
      return UserCompanyStore.preferences;
    },
  },
  mounted() {
    this.$router.afterEach((to, from) => {
      this.destroyPopper();
    });
  },
  destroyed() {
    this.destroyPopper();
  },
  methods: {
    destroyPopper(): void {
      if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
      }
    },
    setPopper(el: any): void {
      this.destroyPopper();

      if (el.nodeType) this.$vuetify.goTo(el, { offset: 200 });
      this.$nextTick(() => {
        popperInstance = createPopper(el, this.$refs.tooltip.$el, {
          placement: "auto",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
            {
              name: "preventOverflow",
            },
          ],
        });
      });
    },
    nextStep(): void {
      while (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        let el = document.querySelector(this.steps[this.currentStep].target);
        if (el) {
          this.setPopper(el);
          return;
        } else if (this.steps[this.currentStep].virtualTarget) {
          this.setPopper(this.steps[this.currentStep].virtualTarget);
          return;
        }
      }
    },
    previousStep(): void {
      while (this.currentStep > 0) {
        this.currentStep--;
        let el = document.querySelector(this.steps[this.currentStep].target);
        if (el) {
          this.setPopper(el);
          return;
        } else if (this.steps[this.currentStep].virtualTarget) {
          this.setPopper(this.steps[this.currentStep].virtualTarget);
          return;
        }
      }
    },
    startTour(): void {
      this.show = true;
      this.currentStep = -1;
      this.nextStep();
    },
    finishTour(): void {
      this.destroyPopper();
      this.show = false;
    },
  },
});
