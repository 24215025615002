declare global {
  interface Window {
    chatwootSDK: any;
    bugsnagClient: any;
    clipboardData: any;
    $chatwoot: any;
    cpl: {
      $vueBus: any;
      renderCount: number;
      currentRendering: number;
    };
  }
}
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
const debug = process.env.NODE_ENV !== "production";
const whitelabel = {
  support: "support@wns.com",
  name: "WNS",
  url: "https://www.wns.com",
  // color: "#ed1c24",
  color: "#000000",
};
const CARGO_PLANNER_BLUE = "#4678b2";
const urlParams = new URLSearchParams(window.location.search);

const inIframe = !(window === window.parent);
// If in Iframe mode - use provided primary color or default one
const PRIMARY_COLOR =
  inIframe && urlParams.get("primary")
    ? urlParams.get("primary")
    : whitelabel.color;

// TODO: whitelabel
// if (!debug && !inIframe) {
//   (function(d, t) {
//     const BASE_URL = "https://chatwoot.cargo-planner.com";
//     const g = d.createElement(t) as any;
//     const s = d.getElementsByTagName(t)[0];
//     g.src = BASE_URL + "/packs/js/sdk.js";
//     s.parentNode.insertBefore(g, s);
//     g.onload = function() {
//       window.chatwootSDK.run({
//         websiteToken: "bz8YjkcG7eR3p8cmvrmCruJo",
//         baseUrl: BASE_URL,
//       });
//     };
//   })(document, "script");

//   window.addEventListener("afterprint", function(event) {
//     const elements = document.querySelectorAll(
//       ".woot-widget-holder,.woot-widget-bubble"
//     );
//     for (let i = 0; i < elements.length; i++) {
//       elements[i].setAttribute("style", "display:block;");
//     }
//   });
// }
// TODO check this
// window.addEventListener("chatwoot:ready", function() {
//   if (UserCompanyStore.is_authenticated) {
//     Vue.prototype.$populateChatUser(
//       UserCompanyStore.user?.id,
//       UserCompanyStore.user?.email,
//       UserCompanyStore.company?.name
//     );
//   }
// });

// Vue.prototype.$populateChatUser = (
//   userId: number,
//   email: string,
//   company: string
// ) => {
//   window.$chatwoot?.setUser(userId, {
//     email: email,
//     name: company,
//   });
// };

// Vue.prototype.$hideChatWidget = () => {
//   const elements = document.querySelectorAll(
//     ".woot-widget-holder,.woot-widget-bubble"
//   );
//   for (let i = 0; i < elements.length; i++) {
//     elements[i].setAttribute("style", "display:none;");
//   }
// };

import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";
import "typeface-roboto/index.css";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store, { UserCompanyStore } from "./store";
import dimTools from "./misc/dimTools";
import filters from "./filters/index";

import Vuetify from "vuetify/lib";
Vue.use(Vuetify);

import WeightInputField from "@/components/Custom/WeightInputField.vue";
import LengthInputField from "@/components/Custom/LengthInputField.vue";
import RotationInputField from "@/components/Custom/RotationInputField.vue";
import TourComponent from "@/components/Custom/Tour.vue";
import IconComponent from "@/components/Custom/SvgIcon.vue";

Vue.component("length-input-field", LengthInputField);
Vue.component("weight-input-field", WeightInputField);
Vue.component("rotation-input-field", RotationInputField);
Vue.component("app-tour", TourComponent);
Vue.component("icon", IconComponent);

import bugsnag from "@bugsnag/js";
import bugsnagVue from "@bugsnag/plugin-vue";
if (!debug) {
  window.bugsnagClient = bugsnag({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    collectUserIp: false,
    notifyReleaseStages: ["production"],
  });
  window.bugsnagClient.use(bugsnagVue, Vue);
}

router.beforeEach((to, from, next) => {
  if (
    to.name !== "account_settings" &&
    to.name !== "company_settings" &&
    UserCompanyStore.is_authenticated
  ) {
    if (!UserCompanyStore.user.accepted_policy) next("/profile/account");
    else if (UserCompanyStore.company.subscription_end < new Date()) {
      next(UserCompanyStore.user.is_admin ? "/company" : "/profile/account");
    } else next();
  } else {
    next();
  }
});
// TODO: whitelabel set support mail and service name
Vue.prototype.$whitelabel = whitelabel;

if (inIframe) {
  const navigation = urlParams.get("navigation");
  if (navigation) {
    Vue.prototype.$navigation = navigation.split(",");
  }
}

Vue.prototype.$list_types = [
  { text: "Sea", value: "SEA" },
  { text: "Road", value: "ROAD" },
  { text: "Air", value: "AIR" },
  { text: "Packages and pallets", value: "PALL" },
];
Vue.prototype.$toSI = dimTools.toSI;
Vue.prototype.$fromSI = dimTools.fromSI;
Vue.prototype.$listTypeDisplayNames = (t: string) => {
  return Vue.prototype.$list_types.find(
    (listType: { text: string; value: string }) => listType.value === t
  ).text;
};
Vue.prototype.$typeNames = (t: string) => {
  switch (t) {
    case "PALL":
      return "pallet";
    case "ROAD":
      return "trailer";
    case "AIR":
      return "ULD";
    default:
      return "container";
  }
};
Vue.prototype.$setNames = (t: string) => {
  switch (t) {
    case "AIR":
      return "Aircraft";
    case "ROAD":
      return "Road train";
    case "SEA":
      return "Vessel";
    case "PALL":
      return "Warehouse";
    default:
      return "Set";
  }
};
Vue.config.productionTip = false;

// Vue.use(filters);
// for (const filter in filters) {
//   Vue.filter(filter, (filters as any)[filter]);
// }

new Vue({
  el: "#app",
  router,
  store,
  filters,
  vuetify: new Vuetify({
    theme: {
      options: { customProperties: true },
      themes: {
        light: {
          primary: PRIMARY_COLOR,
        },
        dark: {
          primary: "#3482DB",
        },
      },
    },
    icons: {
      iconfont: "mdi",
      values: {
        ROAD: {
          component: IconComponent,
          props: {
            icon: "truck",
          },
        },
        SEA: {
          component: IconComponent,
          props: {
            icon: "container",
          },
        },
        AIR: {
          component: IconComponent,
          props: {
            icon: "airplane",
          },
        },
        PALL: {
          component: IconComponent,
          props: {
            icon: "pallet",
          },
        },
      },
    },
  }),
  render: (h) => h(App),
}).$mount("#app");
