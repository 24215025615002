














































































































































































































































































































import Vue from "vue";
import { SceneManager } from "@/graphics/sceneManager";
import TermsAndConditionsModal from "@/components/Modals/TermsAndConditions.vue";
import ShareLoadlistModal from "@/components/Modals/ShareLoadlist.vue";
import API from "@/API";
import { LoadlistStore, UserCompanyStore } from "./store";
import { CompanyStoreTemplate, User } from "./models/UserCompanyModel";
import { Loadlist } from "./models/LoadlistModel";

const debug = process.env.NODE_ENV !== "production";
const PAGE_REFRESH_INTERVALL = debug ? 10 * 60000 : 30 * 60000;
const IS_IFRAME = !(window === window.parent);

export default Vue.extend({
  name: "app",
  components: {
    TermsAndConditionsModal,
    ShareLoadlistModal,
  },
  data() {
    return {
      iframeMode: IS_IFRAME,
      miniDrawer: true,
      showTOC: false,
      showNewUpdateModal: false,
      showGLModal: false,
      showShareDialog: false,
      tmp_access_code: null as string,
    };
  },
  computed: {
    pageTitle(): string {
      return this.loadlist?.name || this.$route.meta.title;
    },
    currentUrl(): string {
      var pathArray = location.href.split("/");
      var host = pathArray[2];
      return host;
    },
    currentYear(): number {
      return new Date().getFullYear();
    },
    showErrorSnackbar: {
      get(): boolean {
        return !!this.error_message;
      },
      set(value: boolean): void {
        this.$store.commit("setErrorMessage", null);
      },
    },
    is_authenticated(): boolean {
      return UserCompanyStore.is_authenticated;
    },
    user(): User {
      return UserCompanyStore.user;
    },
    company(): CompanyStoreTemplate {
      return UserCompanyStore.company;
    },
    error_message(): string {
      return this.$store.getters.error_message;
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
  },
  async created(): Promise<void> {
    try {
      await SceneManager.init();
    } catch (e) {
      this.showGLModal = true;
    }

    (window as any).cpl = {
      renderCount: 0,
    };

    //PAGE REFRESH TIMER
    this.checkForUpdate();
    setInterval(() => {
      this.checkForUpdate()
        .then((newVersionAvailable: boolean) => {
          this.showNewUpdateModal = newVersionAvailable;
          console.log("New update available");
        })
        .catch((e) => {
          console.log("Could not check for update");
        });
    }, PAGE_REFRESH_INTERVALL);
  },
  mounted() {
    if (this.$route.query.dark) this.$vuetify.theme.dark = true;
  },
  methods: {
    refreshPage(): void {
      window.location.reload();
    },
    updateAccessCode(): void {
      API.instance.defaults.headers.common[
        "tmp_access_code"
      ] = this.tmp_access_code;
      this.$store.commit("setShowAccessCodeRequiredModal", false);
    },
    // TODO: whitelabel
    logout() {
      UserCompanyStore.logout(null);
    },
    checkForUpdate(): Promise<boolean> {
      return this.$store.dispatch("checkForUpdate");
    },
  },
});
