




























































































































































































































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "icon",
  props: {
    icon: String,
  },
  mounted() {},
});
