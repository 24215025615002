



































































import { Loadlist } from "@/models/LoadlistModel";
import { LoadlistStore, UserCompanyStore } from "@/store/index";
import Vue from "vue";
export default Vue.extend({
  props: {
    visible: Boolean,
  },

  data() {
    return {
      shareLink: "",
    };
  },
  computed: {
    show: {
      get(): boolean {
        if (this.visible) {
          this.shareLink = this.thisUrl();
        }
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    public_access: {
      get(): "RO" | "RW" | null {
        return this.loadlist ? this.loadlist.public_access : null;
      },
      set(value: "RO" | "RW" | null): void {
        LoadlistStore.setLoadlistProperty({
          key: "public_access",
          value: value,
        });
        this.saveLoadlist();
      },
    },
    publicAccessOptions() {
      return [
        { text: "Disabled", value: null },
        { text: "Read Only", value: "RO" },
        { text: "Read and Write", value: "RW" },
      ];
    },
    loadlist(): Loadlist {
      return LoadlistStore.loadlist;
    },
  },
  methods: {
    thisUrl(): string {
      let route = this.$router.resolve({
        name: "loadlist",
        params: {
          id: this.loadlist.id,
        },
      });
      const url = new URL(window.location.origin + route.href);
      url.searchParams.set("length_dim", UserCompanyStore.length_dim);
      url.searchParams.set("weight_dim", UserCompanyStore.weight_dim);
      return url.href;
    },
    selectLink(e: any): void {
      e.target.select();
    },
    copyLink(): void {
      (this.$refs.urlTextField as any).$refs.input.select();
      document.execCommand("copy");
    },
    saveLoadlist() {
      LoadlistStore.saveLoadlist();
    },
  },
});
